import { Component, OnInit, OnChanges, EventEmitter, Output, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, Validators, FormControl, ValidatorFn, FormArray, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DynamicFormControl, TypeField, DynamicItens, DynamicFormArray } from '../dynamic-forms.model';
import {
  trigger, state, style, transition,
  animate, group, query, stagger, keyframes
} from '@angular/animations';
import { Observable, Subject, Subscription } from 'rxjs';
@Component({
  selector: 'app-form-array',
  templateUrl: './form-array.component.html',
  styleUrls: ['./form-array.component.scss'],
  animations: [
    trigger('panelInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('700ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('700ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class FormArrayComponent implements OnInit {
  @Output() size = new EventEmitter<any>();
  @Input() @Output() form: FormGroup
  @Input() model: DynamicFormArray
  @Output() eventEmmit = new EventEmitter<any>();
  listOptionsCache: DynamicItens[];
  @Input() showErros: boolean;
  control: DynamicFormArray
  public showArray: boolean = true;
  ngOnInit() {
    this.control = this.model
  }
  constructor(private fb: FormBuilder) {
    
  }
  disable(control) {
    if (control.opaque) {
      return "disabled opacity"
    }
  }
  AddFirstArray() {
    var form = new FormGroup({});
    form.setParent(this.formArrayItem);

    this.control.control.forEach(item => {
      if (item instanceof DynamicFormControl)
        form.addControl(item.formName, this.constructorForm(item))
    });
    this.formArrayItem.push(form)
  }
  returnControlArray(index, form) {
    return (form.controls[this.control.formName] as FormArray).controls[index] as FormGroup
  }
  get formArrayItem(): FormArray {
    return <FormArray>this.form.get(this.control.formName);
  }
  returnControl(form, index): FormGroup {
    return (form.controls[this.control.formName] as FormArray).controls[index] as FormGroup
  }
  renderDynamicFormControl(control): DynamicFormControl[] | DynamicFormArray {
    var controls = [];
    control.forEach(item => {
      controls.push(item)
    });
    return controls;
  }

  CKT(control): string {
    if (control instanceof DynamicFormArray) {
      return "DFA";
    } else {
      return "DFC";
    }

  }

  formSize(element) {
    var columns = 'col-lg-' + element.columns;
    return columns;
  }
  formVisible(element) {
    if(element.visible == false){
      return "hidden ";
    }
    return "";
  }
  formArraySize(element: DynamicFormArray) {
    var columns = 'col-lg-' + element.internal;
    return columns
  }

  renderFormArray() {
    var itens = [];
    var item = this.formArrayItem.controls
    item.forEach(p => {
      itens.push(p)
    });
    return itens
  }

  AddArray(item) {
    var index = 0;
    var formArrayGroup = new FormGroup({});
    item.control.forEach(array => {
      if (array instanceof DynamicFormControl) {
        formArrayGroup.addControl(array.formName, this.constructorForm(array as DynamicFormControl));
      } else {
        var arrayitem = this.fb.array([])
        arrayitem.setParent(arrayitem)
        arrayitem.push(this.AddArray(array))
        formArrayGroup.addControl(array.formName, arrayitem)
      }
    });
    return formArrayGroup
  }
  TestaForm(formArrayItem) {
    return true;
  }

  AddItemArray(control) {
    if (control.MaxArraySize > this.formArrayItem.length) {
      var formArrayGroup = new FormGroup({});
      this.control.control.forEach(array => {
        if (array instanceof DynamicFormControl) {
          formArrayGroup.addControl(array.formName, this.constructorForm(array as DynamicFormControl));
        } else if (array instanceof DynamicFormArray) {
          formArrayGroup.addControl(array.formName, this.fb.array([]))
          var formArray = formArrayGroup.controls[array.formName] as FormArray;
          formArray.setParent(formArrayGroup)
          formArray.push(this.AddArray(array));
        }
      });
      formArrayGroup.setParent(this.formArrayItem)
      this.formArrayItem.push(formArrayGroup);
    }
  }

  addItenOnArray(mainControl, item, index) {
    var formGroupItem = (this.form.controls[mainControl.formName] as FormArray).controls[index] as FormGroup;
    var formArrayGroup = new FormGroup({});
    item.control.forEach(array => {
      if (array instanceof DynamicFormControl) {
        formArrayGroup.addControl(array.formName, this.constructorForm(array as DynamicFormControl));
      } else if (array instanceof DynamicFormArray) {
        formArrayGroup.addControl(array.formName, this.fb.array([]))
        var formArray = formArrayGroup.controls[array.formName] as FormArray;
        formArray.setParent(formArrayGroup)
        formArray.push(this.AddArray(array));
      }
    });
    var formArrayAdd = new FormArray([]);
    formArrayGroup.setParent(formArrayAdd);
    formGroupItem.addControl(item.formName, formArrayAdd)
    var formarray = formGroupItem.controls[item.formName] as FormArray
    formarray.controls.push(formArrayGroup)
    var main = (mainControl as DynamicFormArray)
    main.control.push(item);
  }
  EventRoot: Observable<any>
  private subEvent: Subscription;
  arrayEvent(event, control, index, mainControl) {
    if (event.EventRoot) {
      this.eventEmmit.emit({ name: event.name, item: event.item, EventRoot: event.EventRoot, control: event.control, formindex: event.formindex, form: event.form, mainControl:event.mainControl })
    } else {
      var subject: Subject<any> = new Subject<any>()
      this.EventRoot = subject
      this.subEvent = this.EventRoot.subscribe(event => {
        this.addItenOnArray(mainControl, event, index)
      });
      this.eventEmmit.emit({ name: event.name, item: event.item, EventRoot: subject, control: control, formindex: index, form: this.form,mainControl:mainControl })
    }
  }
  RemoveItemArray(index) {
    this.formArrayItem.removeAt(index)
    var size = this.formArrayItem.length * this.control.columns
    if (size > 12)
      size = 12
    this.size.emit(size)
  }
  CloneItemArray(control, index) {
    if (control.MaxArraySize > this.formArrayItem.length) {
      var formArrayGroup = new FormGroup({});
      this.control.control.forEach(array => {
        if (array instanceof DynamicFormControl) {
          formArrayGroup.addControl(array.formName, this.constructorForm(array as DynamicFormControl));
        } else if (array instanceof DynamicFormArray) {
          formArrayGroup.addControl(array.formName, this.fb.array([]))
          var formArray = formArrayGroup.controls[array.formName] as FormArray;
          formArray.setParent(formArrayGroup)
          formArray.push(this.AddArray(array));
        }
      });
      formArrayGroup.patchValue(this.formArrayItem.at(index).value)
      formArrayGroup.setParent(this.formArrayItem)
      this.formArrayItem.push(formArrayGroup);
    }
  }
  private constructorForm(control: DynamicFormControl): FormControl {
    const validators: ValidatorFn[] = [];
    if (control.required) { validators.push(Validators.required); }
    if (control.isId) {
      return new FormControl(control.value || 0, validators);;
    }
    if (control.type == TypeField.number || control.type == TypeField.decimal) {
      var formControll = new FormControl(control.value || 0, validators);
      if (control.type == TypeField.decimal) {
        formControll.setValue("0.0");
      } else {
        formControll.setValue("0");
      }
      return formControll;
    }
    if (control.type == TypeField.DropDown || control.type == TypeField.permissionSelect) {
      if (control.required)
        validators.push(Validators.min(1))

      return new FormControl(control.value, validators);
    }
    if (control.type == TypeField.switch) {
      return new FormControl(control.value || false, validators);
    }
    if (control.type == TypeField.currency) {
      if (control.required) {
        validators.push(Validators.min(0.0))
      } else {
        var formControll = new FormControl(control.value || 0, validators);
        formControll.setValue("0.0");
        return formControll;
      }
      return new FormControl(control.value || 0, validators);
    }
    return new FormControl(control.value || '', validators);
  }
}
export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state('in', style({
      'max-height': '500px', 'opacity': '1', 'visibility': 'visible'
    })),
    state('out', style({
      'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
    })),
    transition('in => out', [group([
      animate('400ms ease-in-out', style({
        'opacity': '0'
      })),
      animate('600ms ease-in-out', style({
        'max-height': '0px'
      })),
      animate('700ms ease-in-out', style({
        'visibility': 'hidden'
      }))
    ]
    )]),
    transition('out => in', [group([
      animate('1ms ease-in-out', style({
        'visibility': 'visible'
      })),
      animate('600ms ease-in-out', style({
        'max-height': '500px'
      })),
      animate('800ms ease-in-out', style({
        'opacity': '1'
      }))
    ]
    )])
  ]),
]