import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/Auth/auth.service';
import { MatDialog } from '@angular/material';
import { UserDataComponent } from 'src/app/Admin/Account/user-data/user-data.component';
@Component({
  selector: 'app-user-drop',
  templateUrl: './user-drop.component.html',
  styleUrls: ['./user-drop.component.scss']
})
export class UserDropComponent implements OnInit {


  logo = ""
  prodService: any;
  router: any;
  title: any;
  name:string;
  constructor(private auth: AuthService,public dialog: MatDialog,) {}
  ngOnInit() {
    this.name = this.auth.user.name
  }

  openDados() {
    const dialogRef = this.dialog.open(UserDataComponent, {
      width: 'auto', panelClass: 'col-sm-8',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {  
    }, error => {
    });
  }

}
