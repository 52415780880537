import { Component, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';


// text,email,tel,textarea,password,
@Component({
    selector: 'text-box',
    template: `<div
    [formGroup]="form" class="">
    <input placeholder="{{control.placeHolder}}"
        class="form-control"
        [formControlName]="control.formName"
        [attr.disabled]="!control.visible ? '' : null"
        [id]="control.formName"
        [readonly]="control.readonly"
        type="text">
</div>`,
styleUrls: ['./inputs.scss']
})
export class TextComponent {
    @Input() @Output() control: DynamicFormControl;
    @Input() @Output() form: FormGroup;

    constructor() {

     }
}