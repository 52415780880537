
import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DynamicFormControl, DynamicItens, TypeField } from '../dynamic-forms.model';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {
  @Input() control: DynamicFormControl;
  @Input() formGroupName: string;
  @Input() form: FormGroup;
  @Input() showErros: boolean;
  @Output() eventEmmit = new EventEmitter<any>();
  listOptionsCache: DynamicItens[];
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    
  }
  get isDirty() { return this.form.controls[this.control.formName].dirty; }
  get isValid() {
      const controlForm = this.form.get(this.control.formName);
      const erros = controlForm.errors;
      let hasErros = false;
      if (erros) {
        Object.keys(erros).forEach(function (key, index) {
          hasErros = controlForm.hasError(key);
          if (hasErros) {
            return;
          }
        });
      }
      return !hasErros;
  }


  getDisplayFn() {
    return (val) => this.displayFn(val);
  }

  displayFn(value?: any) {
    if (this.listOptionsCache) {
      return this.listOptionsCache.filter(p => p.key === value)[0].description;
    } else {
      this.http.get<DynamicItens>(`${this.control.urlGetItens}/${value}`).subscribe(res => {
          this.listOptionsCache = [ res ];
          this.form.get(this.control.formName).setValue(res.key);
        });
      return undefined;
    }
  }
}
