import { Component, Input, Output, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';
import { HttpClient } from '@angular/common/http';

// text,email,tel,textarea,password,
@Component({
  selector: 'file-brute-box',
  template: `
  <div class="d-flex">
    <div class="custom-file Add" [formGroup]="form">
      <input type="file" 
        class=" custom-file-input  "
        [id]="control.formName"
        [readonly]="!control.readonly ? '' : null"
        [attr.disabled]="!control.visible ? '' : null"
        (change)="onUpload($event.target.files)"
      >
      <label class="custom-file-label" [for]="control.formName">{{FileName}}</label>
    </div>
    </div>

  `,
  styleUrls: ['./inputs.scss']
})
export class FileBruteComponent implements OnInit {
  ngOnInit(): void {

  }
  FileName = "Selecione o Arquivo"
  @Input() @Output() control: DynamicFormControl;
  @Input() @Output() form: FormGroup;
  constructor(private http: HttpClient) {

  }
  ngOnChange() {

  }

  onUpload(event) {
    if (event.length === 0) {
      return;
    }
    let fileToUpload = <File>event[0];
    this.form.controls[this.control.formName].setValue(fileToUpload);
  }
}
