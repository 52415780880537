import { Component, OnInit, Renderer2, Output, EventEmitter, Input } from '@angular/core';
import { HttpClient } from 'node_modules/@angular/common/http';
import { Router, NavigationEnd } from 'node_modules/@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/Auth/auth.service';
import { SideMenu, MenuItem } from 'src/app/side-menu';

export interface Refresh{
    refreshMenu();
}
export interface CollapseMenu{
    Collapse(collapse:boolean)
}

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit,Refresh {
    isActive: boolean;
    collapsed: boolean;
    public static listitensMenu:CollapseMenu[] = []
    pushRightClass: string;
    
    name = "";
    menuitens:Observable<MenuItem[]>

    @Output() collapsedEvent = new EventEmitter<boolean>();
    constructor(public auth: AuthService, private router: Router,private http:HttpClient) { 
        if (window.innerWidth <= 992 && this.isToggled()) {
            this.toggleSidebar();
        }
        
    }
    refreshMenu(){
        this.menuitens = SideMenu.itens
    }
    ngOnInit() {
        this.isActive = false;
        this.collapsed = false;
        this.pushRightClass = 'push-right';  
        SideMenu.Instance(this.auth,this.http).ConfigureRefresh(this);
    }
    eventCalled() {
        this.isActive = !this.isActive;
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.collapsedEvent.emit(this.collapsed);
        MenuComponent.listitensMenu.forEach(p=>{
            p.Collapse(this.collapsed);
        });
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
 
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    onLoggedout() {
        this.auth.logout();
        this.router.navigate(["/logout"])
    }
}

