import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Refresh } from './Atom/menu/menu.component';
import { AuthService } from './Auth/auth.service';

export class SideMenu {
    public static itens: Observable<MenuItem[]> = of([]);
    private static instance: SideMenu = null;
    private static auth: AuthService;
    private static http: HttpClient;

    public static Instance(auth: AuthService, http: HttpClient): SideMenu {
        this.auth = auth;
        this.http = http;
        if (this.instance == null) {
            this.instance = new SideMenu();
        }
        return this.instance
    }

    constructor() {

    }
    public Refresh() {
        this.MenuInit();
    }
    private static delegateResponse: Refresh = null;
    ConfigureRefresh(delegate: Refresh) {
        SideMenu.delegateResponse = delegate;
        this.Refresh();
    }
    private MenuInit() {
        console.log(SideMenu.auth.user.role);
        if (SideMenu.auth.isAuthenticated())
            if (SideMenu.auth.user.role == "DEV") {
                SideMenu.itens = of(this.ConstroiDEV());
                SideMenu.delegateResponse.refreshMenu()
            } else if (SideMenu.auth.user.role == "ADMIN") {
                this.ConstroiAdmin();
            } else if (SideMenu.auth.user.role == "SERVICO") {
                this.ConstroiServicos()
            } else if (SideMenu.auth.user.role == "CENTRAL") {
                SideMenu.itens = of(this.ConstroiCentral());
                SideMenu.delegateResponse.refreshMenu()
            } else if (SideMenu.auth.user.role == "CONTRATANTE") {
                SideMenu.itens = of( this.ConstroiContratante());
                SideMenu.delegateResponse.refreshMenu()
            } else {
                SideMenu.itens = of([
                    { url: "logout", title: "Sair", icon: "" },

                ]);
                SideMenu.delegateResponse.refreshMenu()
            }
    }
    private ConstroiDEV() {
        return [
            { url: "/dev/index", title: "Icones", icon: "icon-list" },
            { url: "logout", title: "Sair", icon: "" },
        ];//
    }
    private ConstroiContratante() {
        return [
            { url: "/contratante/list-transportes-finalizados", title: "Transportes Finalizados", icon: "" },
            { url: "/contratante/contratos", title: "Contratos", icon: "" },

        ];//
    }
    private ConstroiCentral() {
        return [
            { url: "/atendimento/nova-solicitacao", title: "Nova Solicitação", icon: "" },
            { url: "/atendimento/solicitacoes-abertas", title: "Solicitações Abertas", icon: "", },
            { url: "/atendimento/solicitacoes-finalizadas", title: "Solicitações Finalizadas", icon: "", },
            {
                url: "", title: "Cadastros", itens: [
                    { url: "/atendimento/estabelecimento", title: "Estabelecimentos" },
                ]
            },
        ];
    }
    defaultOpen = false
    private ConstroiAdmin() {
        SideMenu.itens.subscribe(value => {
            var openCoordenacao = value.find(p => p.title == "Coordenação Técnica");
            if (openCoordenacao != undefined)
                this.defaultOpen = openCoordenacao.open
            SideMenu.http.post("api/side-menu/admin", value).subscribe(res => {
                SideMenu.itens = of([
                    {
                        title: "Administrativo", url: "", itens: [
                            { url: "/administrativo/empresa", title: "Empresa" },
                            { url: "/administrativo/contratante", title: "Clientes" },
                            { url: "/administrativo/menu-contratos", title: "Contratos" },
                            {
                                url: "", title: "Geral", itens: [
                                    { url: "/administrativo/", title: "Tipos de Contratos" },
                                    { url: "/administrativo/", title: "Indices de Reajuste" },
                                    { url: "/administrativo/", title: "Modalidades" },
                                    { url: "/administrativo/tipo-empresa", title: "Tipos de Empresa" },
                                    { url: "/administrativo/usuarios", title: "Usuarios Aman" },
                                ]
                            },
                            {
                                url: "", title: "Profissionais", itens: [
                                    { url: "/administrativo/conselhos", title: "Conselhos" },
                                    { url: "/administrativo/profissionais", title: "Profissionais" },
                                ]
                            },
                        ]
                    },
                    {
                        title: "Assessoria", url: "", itens: [
                            { url: "", title: "NENHUM ITEM" },
                        ]
                    },

                    {
                        title: "Transportes", url: "", itens: [
                            { url: "/administrativo/nova-solicitacao", title: "Nova Solicitação" },
                            { url: "/administrativo/solicitacoes", title: "Solicitacões em Aberto" },
                            {
                                url: "", title: "Cadastros", itens: [
                                    { url: "/administrativo/estabelecimento", title: "Estabelecimentos" },
                                    { url: "/administrativo/garagens", title: "Garagens" },
                                    { url: "/administrativo/comorbidade", title: "Comorbidades" },
                                ]
                            },
                            {
                                title: "Veiculos", url: "", itens: [
                                    { url: "/administrativo/combustivel", title: "Combustiveis" },
                                    { url: "/administrativo/montadoras", title: "Montadoras" },
                                    { url: "/administrativo/tipo-veiculo", title: "Tipos de Veiculos" },
                                    { url: "/administrativo/veiculo", title: "Veiculos" },
                                ]
                            },
                            {
                                url: "", title: "Profissionais", itens: [
                                    { url: "/administrativo/conselhos", title: "Conselhos" },
                                    { url: "/administrativo/profissionais", title: "Profissionais" },
                                ]
                            },
                            {
                                url: "", title: "Relatorios", itens: [
                                    { url: "/administrativo/relatorios/transportes-finalizados", title: "Transportes Finalizados" },
                                    { url: "/administrativo/relatorios/profissionais-transporte", title: "Profissionais por Transporte" },
                                ]
                            },
                        ]
                    },
                    {
                        title: "Coordenação Técnica", url: "", open: this.defaultOpen, itens: [
                            {
                                url: "", title: "Prestação de Contas", open: this.defaultOpen, itens: [
                                    { url: "administrativo/servico/modelo", title: "Modelos", open: this.defaultOpen},
                                    { url: "", title: "Prefeituras", open: this.defaultOpen, itens: res },
                                ]
                            },
                        ]
                    },
                ] as MenuItem[]);
                SideMenu.delegateResponse.refreshMenu()

            });
        });
    }
    private ConstroiServicos() {
        SideMenu.itens = of([
            { url: "/", title: "Carregando" },
        ] as MenuItem[]);
        SideMenu.delegateResponse.refreshMenu()
        SideMenu.http.get("api/side-menu/user").subscribe(res => {
            SideMenu.itens = of(res as MenuItem[]);
            SideMenu.delegateResponse.refreshMenu()
        })
    }
}
export interface MenuItem {
    id?: any;
    icon?: string
    title: string;
    url?: string;
    itens?: MenuItem[];
    open?: boolean,
    style?: string
}