import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl, DynamicItens, TypeField } from '../dynamic-forms/dynamic-forms.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// text,email,tel,textarea,password,
@Component({
    selector: 'select-box',
    template: `
    <div [formGroup]="form" [id]="control.formName" 
    [attr.disabled]="!control.visible ? '' : null"
    [ngClass]=" { 'disabled grey': control.readonly  } ">
        <div class="d-flex select-component " style='flex-direction: row-reverse'>
            <mat-form-field>
                <mat-select [formControlName]="control.formName" (change)="onChange($event.target.value)">
                    <mat-option *ngFor="let options of options" [value]="options.key">
                        {{options.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div style='position: absolute;align-self: center;pointer-events: none;'>
                <span *ngIf="form.controls[this.control.formName].valid && form.controls[this.control.formName].value !='0'" class="icon-rd-bd-confirm mr-1" style="align-self: center; color:green;"></span>
                <span class="icon-botton-arrow-sign" style="margin-right: 10px; position: relative;"></span>
            </div>
        </div>
      </div> `,
      styleUrls: ['./select.component.scss']
})
export class SelectBoxComponent implements OnInit {
    @Input() @Output() control: DynamicFormControl;
    @Input() @Output() form: FormGroup;
    @Output() select = new EventEmitter<any>();
    options:DynamicItens[];
    listOptionsCache: DynamicItens[];

    constructor(private http: HttpClient) { }
    onChange(event) {
        if (this.control.type == TypeField.permissionSelect) {
            if (event != "0") {
                this.http.get<any>("api/select/route-permission/" + event).subscribe(
                    res => {
                        this.select.emit({ name: "select", item: res })
                    }
                )
            }
        } else if (this.control.type == TypeField.branchPicker) {
            this.select.emit({ name: "branch-select", item: event })
        }
    }

    ngOnInit(): void {
        this.loadUrl();       
    }
    loadUrl() {
        if (this.control.urlGetItens) {
            this.http.get<DynamicItens[]>("api/Select/" + this.control.urlGetItens).subscribe(res => {
                this.control.options = res
                this.options = this.opcoesList(this.control)
            });
        }
    }
    opcoesList(control): DynamicItens[] {

        if (typeof control.options === 'string') {
            const list = new Array<DynamicItens>();
            const opcoes = (<string>control.options).split(';');
            for (let index = 0; index < opcoes.length; index++) {
                const item = opcoes[index];
                const itens = item.split('=');
                if (itens.length > 1) {
                    list.push({ key: itens[0], description: itens[1] });
                } else {
                    list.push({ key: itens[0], description: itens[0] });
                }
            }
            return list;
        } else {
            var list = new Array<DynamicItens>();
            list.push({ key: 0, description: "Selecione" });
            for (let index = 0; index < control.options.length; index++) {
                list.push({ key: control.options[index].key, description: control.options[index].description });
            }
            if (control.required) {
            }
            return list;
        }
    }
}