import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DynamicFormControl, DynamicItens } from '../../dynamic-forms/dynamic-forms.model';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {
  /*constructor(private http: HttpClient) { }
  config = {
    displayKey: "description", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Selecione', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 1, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'Outros', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'Nenhum Resultado encontrado', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Buscar', // label thats displayed in search input,
    searchOnKey: 'description', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr', // the direction of the search input can be rtl or ltr(default)
  }
  @Output() multiselect = new EventEmitter<any>();

  myControl = new FormControl();
  ngOnInit() {
    if (this.controller.urlGetItens)
      this.http.get<any[]>(this.controller.urlGetItens).subscribe(res => {
        this.controller.options = res
      });
    this.form.controls[this.controller.formName].valueChanges
      .subscribe(async value => {
        if (!this.isrefresh) {
          while (this.controller.options.length == 0)
            await this.delay(50);
          if (value.length > 0) {
            var arrayitem: DynamicItens[] = [];
            value.forEach(q => {
              var item = (this.controller.options as DynamicItens[]).find(p => p.key == q)
              arrayitem.push(item)
              this.myControl.setValue(arrayitem)
            });
           
            this.isrefresh = true
          }
        }
      });
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  isrefresh = false;
  @Input() @Output() controller: DynamicFormControl;
  @Input() @Output() form: FormGroup;

  ChangeOptions(event) {
    this.isrefresh = true
    if (this.myControl.value.length == 0) {
      this.form.controls[this.controller.formName].setValue(null);
    } else {
      var itens = []
      this.myControl.value.forEach(p => {
        itens.push(p.key);
      });
      this.form.controls[this.controller.formName].setValue(itens);
    }
    this.multiselect.emit({ name: this.controller.formName, item: this.form.controls[this.controller.formName].value });
  }*/
  ngOnInit() {}
}
