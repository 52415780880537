import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';
import { HttpClient } from '@angular/common/http';

// text,email,tel,textarea,password,
@Component({
  selector: 'slider-box',
  template: `
  <div >
    <label class="switch tiny " [ngClass]=" { 'disabled': control.readonly  } " [formGroup]="form"  >
      <input 
      
        class="form-control "
        [id]="control.formName"
        [formControlName]="control.formName"
        [attr.disabled]="!control.visible ? '' : null"
        type="checkbox"
        (change)="changeEvent()" 
      >
    <span class="slider round tiny"></span>
    </label>
    </div>
  `,
  styles: [
    `
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 30px;
    }      
    .switch.tiny{
      width: 50px;
      height: 25px;
    }
     .switch.small{
        width: 35px;
        height: 20px;
      }
    .switch input { 
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .slider:before {
     position: absolute;
     content: "";
     height: 20px;
     width: 20px;
     left: 4px;
     bottom:5px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    .slider.small:before{   
       height: 15px;
       width: 15px;   
        left: 3px;
        bottom:3px;
      }
      .slider.tiny:before{
        bottom:4px;
        height: 17px;
        width: 17px;     
        left: 3px;
      }
    
    input:checked + .slider {
      background-color: #2ec4ab;
    }
       input:checked + .slider.orange {
      background-color: #ff4423;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
      
    }
    input:checked + .slider.small:before{     
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
      }
    input:checked + .slider.tiny:before{     
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
      }

    .slider.round {
      border-radius: 34px;
    }
    
    .slider.round:before {
      border-radius: 50%;
    }
    `
  ]
})
export class SliderComponent implements OnInit {
  ngOnInit(): void {

  }
  @Output() slider = new EventEmitter<any>();
  @Input() @Output() control: DynamicFormControl;
  @Input() @Output() form: FormGroup;
  changeEvent() {
    this.slider.emit({ name: this.control.formName, item: null })
  }
}