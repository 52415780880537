import { Injectable } from '@angular/core';
import { HttpRequest, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { tokenKey } from '@angular/core/src/view';
import { Criptografy } from './criptografy.service';

const TOKEN_KEY = '71a7f6d72f6bf7c740028d8661595bfe';
const LOGO_KEY =  '35734651651d51f2203d512c3d35ef3a';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url = '/connect/token';
  private cachedRequests: Array<HttpRequest<any>> = [];
  private token: Token;
  private body = new URLSearchParams();

  constructor(public http: HttpClient) {
  }

  public getToken(): Token {
    try {
      return JSON.parse(localStorage.getItem(TOKEN_KEY), (key, value) => { if (key === 'expireDate') { return new Date(value); } else { return value; } });
    } catch (e) {
      return null;
    }
  }

  get user(): AcessToken {
    const token = this.getToken();
    if (token) {
      return { username: token.username, name: token.name, role: token.role };
    }
    return null;
  }


  public login(email: string, password: string): Observable<any> {
    this.body.set('username', email);
    this.body.set('password', password);
    this.body.set('grant_type', 'password');

    return this.http.post<Token>(this.url, this.body.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).pipe(
      map(
        result => {
          this.tokenSet(result)
        }
      )
    );
  }
  tokenSet(result){
    if (result) {
      this.token = result;
      //this.token.expireDate =  new Date(Date.now() + (this.token.expireAt * (-1)));
      localStorage.setItem(TOKEN_KEY, JSON.stringify(this.token));
      return { status: true, token: this.token };
    }
    return { status: false };
  }

  logout(): any {
    localStorage.removeItem(TOKEN_KEY);
  }

  public isAuthenticated(): boolean {
    return !this.expired();
  }
  public isAdmin(): boolean {
    return this.getToken().role == "ADMIN";
  }
  private expired(): boolean {
    const token = this.getToken();
    return token == null || token.expireDate.getTime() < Date.now();
  }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }
}
class Token {
  public token: string;
  public expireAt: number;
  public role: string;
  public name: string;
  public username: string;
  public expireDate: Date = new Date();
}

class AcessToken {
  public username: string;
  public name: string;
  public role: string;
}
