import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/Auth/auth.service';
import { DynamicFormControl, TypeField } from '../../dynamic-forms/dynamic-forms.model';

@Component({
  selector: 'app-filtros-modal',
  templateUrl: './filtros-modal.component.html',
  styleUrls: ['./filtros-modal.component.scss']
})
export class FiltrosModalComponent implements OnInit {
  itens: DynamicFormControl[];
  changeEvent = new EventEmitter<any>();
  form: FormGroup;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private auth: AuthService,
    public dialogRef: MatDialogRef<FiltrosModalComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.form = data.form;
    this.itens = data.itens
    this.changeEvent = data.changeEvent
    this.FiltersModel(this.itens);
  }

  ngOnInit() {
  }
  eventEmmit(event) {
    if (event.type != undefined) {
      this.changeEvent.emit({ name: event.name, type: event.type, item: event.item, form: this.form })
    } else {
      this.changeEvent.emit({ name: event.name, item: event.item, form: this.form })
    }
  }
  renderDynamicFormControl(control): DynamicFormControl[] {
    var controls = [];
    control.forEach(item => {
      if (item.visible)
        controls.push(item)
    });
    return controls;
  }
  SaveClose(){
    this.dialogRef.close(this.form);
  }
  Close(){
    this.dialogRef.close(null);
  }
  formSize(element) {
    var columns = 'col-lg-' + element.columns;
    return columns
  }

  FiltersModel(value: DynamicFormControl[]) {
    this.itens = value;
    value.forEach(item => {
      if (item instanceof DynamicFormControl) {
        this.form.addControl(item.formName, this.constructorForm(item as DynamicFormControl));
      }
    });
  }
  private constructorForm(control: DynamicFormControl): FormControl {
    const validators: ValidatorFn[] = [];
    if (control.required) { validators.push(Validators.required); }
    if (control.isId) {
      return new FormControl(control.value || 0, validators);
    }
    if (control.type == TypeField.DropDown || control.type == TypeField.permissionSelect) {
      if (control.required)
        validators.push(Validators.min(1))
      return new FormControl(control.value, validators);
    }
    if (control.type == TypeField.number || control.type == TypeField.decimal) {
      var formControll = new FormControl(control.value || 0, validators);
      if (control.type == TypeField.decimal) {
        formControll.setValue("0.0");
      } else {
        formControll.setValue("0");
      }
      return formControll;
    }
    if (control.type == TypeField.switch) {
      return new FormControl(control.value || false, validators);
    }
    if (control.type == TypeField.autocomplete || control.type == TypeField.e_autocomplete || control.type == TypeField.autocomplete_add) {
      if (control.required)
        validators.push(Validators.min(1))
      var formControll = new FormControl(control.value || 0, validators);
      if (control.required == true)
        formControll.setValue("0");
      return formControll;
    }
    if (control.type == TypeField.currency) {
      if (control.required) {
        validators.push(Validators.min(0))
      } else {
        var formControll = new FormControl(control.value || 0, validators);
        formControll.setValue("0.0");
        return formControll;
      }
      return new FormControl(control.value || 0, validators);
    }
    return new FormControl(control.value || '', validators);
  }
}
