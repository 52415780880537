import { Component, OnInit, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-hour-select',
  templateUrl: './hour-select.component.html',
  styleUrls: ['./hour-select.component.scss']
})
export class HourSelectComponent implements OnInit {
  @Input() formName: string;
  @Input() @Output() form: FormGroup;
exportTime = { hour: '00', minute: '0', meriden: 'PM', format: 24 };
  constructor() {
    
  }
  hourMask = hourMask();
  ngOnInit(): void {
      if(this.form.controls[this.formName].value !=""){
          var hourData = (this.form.controls[this.formName].value as string).split(':');
          this.exportTime.hour =hourData[0];
          this.exportTime.minute = hourData[1];
      }
  }
  onChangeHour(event) {
      if(event.hour){
          this.form.controls[this.formName].setValue(event.hour + ":" + event.minute)
      }else{
          this.form.controls[this.formName].setValue(event.srcElement.value)
      }
  }
}
export function hourMask() {
    return [/\d/, /\d/,  ':', /\d/, /\d/];
}