  import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
  import { FormGroup, FormControl } from '@angular/forms';
  import { DynamicFormControl, DynamicItens, TypeField } from '../../dynamic-forms/dynamic-forms.model';
  import { Pipe, PipeTransform } from '@angular/core';
  import { Observable } from 'rxjs';
  import { HttpClient } from '@angular/common/http';
  import { startWith, map } from 'rxjs/operators';
  // text,email,tel,textarea,password,
  @Component({
    selector: 'color-select-box',
    template: `
    
    <div class="control appSelect" [ngClass]=" { 'disabled opac': controller.readonly  }">
      <mat-form-field >
        <div class="d-flex">
          <input type="text"
            class="form-control"
            placeholder="Selecione"
            aria-label="Number"
            matInput
            [formControl]="myControl"
            [matAutocomplete]="auto"
            (click)="changeValue()">
          <span *ngIf="form.controls[this.controller.formName].valid && form.controls[this.controller.formName].value !='0'"  (click)="changeValue()" class="icon-rd-bd-confirm mr-1" style="align-self: center; color:green;"></span>
          <span class="icon-botton-arrow-sign" style="align-self: center;"  (click)="changeValue()"></span>
        </div>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onChange($event.option.value)" >
          <mat-option *ngFor="let option of this.filteredOptions | async" [value]="option.description" style="padding: 0px !important;">
            <div class="row" style="align-items: center; margin-left:1px ;flex-flow: initial">
              <app-color-circle [cor1]="option.cor1" [cor2]="option.cor2" class="mx-1 mr-3"></app-color-circle> {{ option.description }}
            </div>
          </mat-option>
          <mat-option *ngIf="size==0" [value]="0" >
            Nenhuma informação encontrada
          </mat-option>
        </mat-autocomplete>
    </mat-form-field>
  </div>
    
  `, styleUrls: ['../AutoComplete.component.scss']
  })
  export class ColorSelectBoxComponent implements OnInit {

    @Input() @Output() controller: DynamicFormControl;
    @Input() @Output() form: FormGroup;
    @Output() autoSelect = new EventEmitter<any>();
    filteredOptions: Observable<DynamicItens[]>;
    myControl = new FormControl();
    constructor(private http: HttpClient) { }
    size = 1;
    ngOnInit() {
      if (this.controller.urlGetItens)
        this.http.get<any[]>(this.controller.urlGetItens).subscribe(res => {
          this.controller.options = res
          var option = this.controller.options.find(p => p.key == this.form.controls[this.controller.formName].value);
          if (option)
            this.myControl.setValue(option.description)
          
        });
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
        this.form.controls[this.controller.formName].valueChanges
        .subscribe(value => {
            if(!this.isrefresh){
              this.myControl.patchValue((this.controller.options as DynamicItens[]).find(p => p.key == value).description)
              this.isrefresh=true
            }
          });
    }
    isrefresh = false;
    changeValue() {
      this.myControl.setValue('');
      this.form.controls[this.controller.formName].setValue(null);
    }
    private _filter(value: string): DynamicItens[] {
      if (this.controller.options && value != "0") {
        const filterValue = value.toLowerCase();
        var options = (this.controller.options as any[]).filter(option => (option.description).toLowerCase().includes(filterValue));
        this.size = options.length;
        return options;
      }
    }
    onChange(option) {
      if (option) {
        var _optionItem = (this.controller.options as DynamicItens[]).find(p => p.description == option);
        this.form.controls[this.controller.formName].setValue(_optionItem.key);
        this.autoSelect.emit({ name: this.controller.formName, item: _optionItem.key })
      } else {
        this.form.controls[this.controller.formName].setValue(null);
        this.myControl.setValue('');
      }
    }
  }