import { Component, Input, Output, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';
import { HttpClient } from '@angular/common/http';

// text,email,tel,textarea,password,
@Component({
  selector: 'image-box',
  template: `
    <div [formGroup]="form">
      <div *ngIf="!control.value" class="drop-container dropzone" dropZone (hovered)="toggleHover($event)"
        (dropped)="onUpload($event.target.files)" [class.hovering]="isHovering">
        <p class="m-0">
          Arraste a imagem ou 
          <label class="upload-button">
            <input type="file" multiple=""  (change)="onUpload($event.target.files)"> Procure
          </label>
          para anexar.
        </p>
      </div>
      <div *ngIf="control.value">
        <div class="card" style="text-align: -webkit-center;">
        <span (click)="remove()" class="icon-rd-bd-remove" style = "align-self: flex-end; margin: 5px 5px 0px 0px; color: #ff4423;"></span>
          <img class="card-img-top" [src]="control.value.data" style="max-width:40%; margin-bottom: 20px;"> 
        </div>
      </div>
    </div> 
  `,
  styles: [
    `
    .drop-container {
      background: #fff;
      border-radius: 6px;
      height: 150px;
      width: 100%;
      box-shadow: 1px 2px 20px hsla(0,0%,4%,.1);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed #c0c4c7;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #c0c4c7; 
    }
    .upload-button {
      display: inline-block;
      border: none;
      outline: none;
      cursor: pointer;
      color: #5754a3;
    }
    .upload-button input {
      display: none;
    }
    .dropzone { 
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; 
      border-radius: 5px;
      background: white;
      margin: 10px 0;
    }
    .dropzone.hovering {
        border: 2px solid #f16624;
        color: #dadada !important;
    }
    progress::-webkit-progress-value {
      transition: width 0.1s ease;
    }
    `
  ]
})
export class ImageComponent implements OnInit {
  ngOnInit(): void {
    var value = this.form.controls[this.control.formName].value
    this.control.value = value;
    this.form.get(this.control.formName).valueChanges.subscribe(value => { 
      if (value != null) 
        this.control.value = value
      }
    );
  }
  @Input() @Output() control: DynamicFormControl;
  @Input() @Output() form: FormGroup;
  constructor(private http: HttpClient) {

  }
  ngOnChange() {

  }
  remove() {
    this.control.value = null;
    this.form.controls[this.control.formName].setValue(null);
  }
  onUpload(event) {
    const reader = new FileReader();
    if (event && event.length) {
      const [file] = event;
      reader.readAsDataURL(file);
      if (file.size <= 3145728) {
        if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {
          reader.onload = () => {
            var image = new ImageModel(file.name, file.type, file.size, reader.result)
            this.control.value = image;
            this.form.controls[this.control.formName].setValue(image);
          };
        } else {
          alert("Extensão não suportada. Optar por PNG, JPEG ou JPG")
        }
      } else {
        alert("Imagem muito grande. Escolher Imagens com ate 3MB")
      }
    }
  }
}
export class ImageModel {
  id: any
  name: string;
  extension: string
  size: string
  data: any
  constructor(
    name: string,
    extension: string,
    size: string,
    data: any
  ) {
    this.name = name || "";
    this.extension = extension || "";
    this.size = size || "";
    this.data = data || "";
  }
}