import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from './app.component';
import { registerLocaleData } from "@angular/common";
import { AtomModule } from './atom.module';
import { MaterialModule } from './material.module';
import { TextMaskModule } from 'node_modules/angular2-text-mask/dist/angular2TextMask';
import { AppRoutingModule } from './app-routing.module';
import { MenuComponent } from './Atom/menu/menu.component';
import { LayoutComponent } from './Atom/layout/layout.component';
import { UserDropComponent } from './Atom/header/user-drop/user-drop.component';
import { UserNotificationComponent } from './Atom/header/user-notification/user-notification.component';
import { HeaderComponent } from './Atom/header/header.component';
import { TokenInterceptor } from './Auth/token.interceptor';  
import { HeaderSidebarComponent } from './Atom/menu/header-sidebar/header-sidebar.component';
import ptBr from '@angular/common/locales/pt';
import { MenuItemComponent } from './Atom/menu/menu-item/menu-item.component';
import { FiltrosModalComponent } from './Atom/dynamic-list/filtros-modal/filtros-modal.component';
import { LoginComponent } from './Admin/Account/login/login.component';
import { LogoutComponent } from './Admin/Account/logout/logout.component';
import { UserDataComponent } from './Admin/Account/user-data/user-data.component';
registerLocaleData(ptBr)
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LayoutComponent,
    UserDropComponent,
    UserNotificationComponent,
    HeaderComponent,
    LoginComponent,
    LogoutComponent,
    HeaderSidebarComponent, 
    MenuItemComponent, 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AtomModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TextMaskModule,
    MaterialModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: Date, useValue: new Date() }
  ],exports:[
    UserDataComponent
  ] ,
  bootstrap: [AppComponent]
})
export class AppModule { }
