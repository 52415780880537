import { Component, Input, Output, ViewChild, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';

@Component({
    selector: 'time-picker-box',
    template: `<div
    [formGroup]="form">
        <material-timepicker 
         #mattimePick 
            style="flex-flow: column;"
            [userTime]="exportTime"
            label=""  
            [class.disabled]="control.readonly"
            color="primary"  
            appearance="outline"
            (change)="onChangeHour($event)" 
        ></material-timepicker>
</div>`,
    styleUrls: ['./inputs.scss'],
    styles: [
        `
    .disabled {
        pointer-events: none;
        cursor: default;
        color: #eaecf4;
      }
    `
    ]
})
export class TimePickerComponent implements OnInit {
    @Input() @Output() control: DynamicFormControl;
    @Input() @Output() form: FormGroup;
        exportTime = { hour: '00', minute: '0', meriden: 'PM', format: 24 };
    constructor() {
       
    }
    ngOnInit(): void {
        if(this.form.controls[this.control.formName].value != ""){
            var hourData = (this.form.controls[this.control.formName].value as string).split(':');
            this.exportTime.hour =hourData[0];
            this.exportTime.minute = hourData[1];
        }
    }
    onChangeHour(event) {
        if(event.hour){
            this.form.controls[this.control.formName].setValue(event.hour + ":" + event.minute)
        }else{
            this.form.controls[this.control.formName].setValue(event.srcElement.value)
        }
    }
}