
import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource, PageEvent, MatSort, MatPaginator, MatDialog } from '@angular/material';
import { DynamicListControl, Pagination, botaoList } from './dynamic-list.model';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FiltrosModalComponent } from './filtros-modal/filtros-modal.component';
@Component({
  selector: 'app-dynamic-list',
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.scss']
})
export class DynamicListComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  _listModel: DynamicListControl;
  _pagination: Pagination;
  @Output() delete = new EventEmitter<any>();
  @Output() action = new EventEmitter<any>();
  @Output() filter = new EventEmitter<any>();

  length = 0;
  pageSize = 50;
  pageSizeOptions: number[] = [5, 10, 25, 50,75, 100];
  pageEvent: PageEvent;
  pageIndex = 0;
  filterForm: FormGroup;
  @ViewChild(MatSort) sort: MatSort;
  @Input() EventRoot: Observable<any>;
  private subEvent: Subscription;

  constructor(
    private http: HttpClient,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) {
    this._pagination = new Pagination();
    this._pagination.pageSize = 50;
    this._pagination.pageIndex = 0;
    this._pagination.direction = 'asc';
    this.filterForm = fb.group({});
  }

  ngOnInit() {
    this.refresh();
    if (this.EventRoot != null)
      this.subEvent = this.EventRoot.subscribe((event) => {
        if (event.name == "refresh") {
          this.refresh()
        }
      });
  }

  openFiltros(itens) {
    const dialogRef = this.dialog.open(FiltrosModalComponent, {
      width: 'auto', panelClass: 'col-sm-9',
      data: {
        itens: itens,
        changeEvent:this.action,
        form:this.filterForm
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.filterForm = result
        this.refresh()
      }
    }, error => {
    });
  }

  firstRun: boolean = true
  refresh() {
    var params = {}
    this.filter.emit(this.filterForm.value)
    if (this.filterForm && !this.firstRun) {
      params = {
        direction: this._pagination.direction,
        pageIndex: this._pagination.pageIndex,
        pageSize: this._pagination.pageSize,
        column: this._pagination.column,
        filtros: this.filterForm.value
      };
    } else {
      params = {
        direction: this._pagination.direction,
        pageIndex: this._pagination.pageIndex,
        pageSize: this._pagination.pageSize,
        column: this._pagination.column
      };
    }
    this.firstRun = false;
    if (this.listModel.urlGetData != "")
      this.http.post<any>(this.listModel.urlGetData, params).subscribe(res => {
        this.length = res.itens;
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.sort = this.sort;
      });
  }
  eventClick() {
    this.action.emit({ name: this.listModel.extraButton });
  }
  formSize(element) {
    var columns = 'col-lg-' + element.headerFilterSize;
    return columns
  }
  evaluate(item, element) {
    if (typeof item.loadButton === 'string') {
      return !(eval(item.loadButton))
    } else
      return false;

  }

  addClick() {
    this.router.navigate([this._listModel.createRoute]);
  }
  paginationEvent(event) {
    this._pagination.pageSize = event.pageSize;
    this._pagination.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.refresh()
  }
  sortData(event) {
    this._pagination.column = event.active;
    this._pagination.direction = event.direction;
    this.refresh();
  }
  actionEvent(name: string, item: any) {

    this.action.emit({ name: name, item: item });
  }
  sizeButtons() {
    var inicialSize = 15;
    if (this.listModel.delete) {
      inicialSize += 50;
    } if (this.listModel.editRoute) {
      inicialSize += 50;
    }
    if (this.listModel.buttonsList) {
      var inicialSize = (inicialSize + (35 * this.listModel.buttonsList.filter(p => p.loadButton == true).length));
      var inicialSize = (inicialSize + (15 * this.listModel.buttonsList.filter(p => typeof p.loadButton === 'string').length));
    }
    return inicialSize + "px"
  }
  editUrl(item: any) {
    var url = this._listModel.editRoute;
    url = url.replace(':id', item.id);

    this.router.navigate([url]);
  }

  deleteClick(item: any) {
    if (confirm('Deseja remover?')) {
      this.http.delete<any>(this.listModel.delete + "/" + item.id).subscribe(res => {
        this.refresh();
        this.delete.emit(item);
      });
    }
  }
  applyFilter(event: Event) {
    /*const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }*/
  }




  get listModel(): DynamicListControl { return this._listModel; }

  @Input() set listModel(value: DynamicListControl) {
    this._listModel = value;
  }
  get displayedColumns(): string[] {
    const cols = this.listModel.columns.map<string>((p) => p.name);
    cols.splice(0, 0, 'actionBtn');
    return cols;
  }
  getElement(element: any, itemName: string): any {
    if (element) {
      let obj = element;
      itemName.split('.').forEach((name) => {
        if (obj) {
          obj = obj[name];
        }
      });
      if (obj instanceof Object) {
        let str = '';
        for (const prop in obj) {
          if (!obj.hasOwnProperty(prop)) { continue; }
          str += (str !== '' ? ', ' : '') + obj[prop];
        }
        return str;
      } else {
        return obj;
      }
    }
    return '';
  }
}
