import { Component, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';

// text,email,tel,textarea,password,
@Component({
    selector: 'divider-box',
    template: `<div></div>`
})
export class DividerComponent {
}