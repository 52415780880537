import { Component, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';


// text,email,tel,textarea,password,
@Component({
    selector: 'datetime-box',
    template: `<div
    [formGroup]="form">
        <input placeholder="Ex:14:23 13/10/1997"
            class="form-control"
            [id]="control.formName"
            [formControlName]="control.formName"
            [attr.disabled]="!control.visible ? '' : null"
            [textMask]="{mask: dateTimeMask}" type=""
            [readonly]="!control.readonly ? '' : null">
</div>`,
    styleUrls: ['./inputs.scss']
})
export class DateTimeComponent {
    @Input() @Output() control: DynamicFormControl;
    @Input() @Output() form: FormGroup;
    dateTimeMask = DateTimeMask();
}
export function DateTimeMask() {
    return [/\d/, /\d/, ':', /\d/, /\d/,  ' ', /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
}