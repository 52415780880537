import { Component, OnInit, OnChanges, EventEmitter, Output, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, Validators, FormControl, ValidatorFn, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DynamicFormControl, TypeField, DynamicItens, DynamicFormArray, DynamicForm } from './dynamic-forms.model';
import { Observable, Subscription } from 'rxjs';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-forms.component.html',
  styleUrls: ['./dynamic-forms.component.scss']
})
export class DynamicFormComponent implements OnInit {

  form: FormGroup;
  formControll: DynamicForm;
  @Output()
  salvar = new EventEmitter<any>();
  @Input() parentEvent: Observable<void>;
  private eventsSubscription: Subscription;
  @Output() cancelar = new EventEmitter();
  @Output() eventEmmit = new EventEmitter<any>();
  formArrayGroup: any;
  showErros = false
  constructor(private title: Title, private http: HttpClient, private fb: FormBuilder) {

    this.form = fb.group({});
  }

  ngOnDestroy() {
    if (this.eventsSubscription)
      this.eventsSubscription.unsubscribe();
  }

  @Input() EventRoot: Observable<any>;
  private subEvent: Subscription;


  ngOnInit() {
    if (this.parentEvent != null)
      this.eventsSubscription = this.parentEvent.subscribe((event) => this.parentEventReceived(event));
    if (this.EventRoot != null)
      this.subEvent = this.EventRoot.subscribe((event) => {
        if (event.name == "refresh") {
          this.refresh()
        }
      });
      this.refresh()
  }
  refresh(){
    var url = this.formControll.urlget
    if (url) {
      this.http.get<any>(url).subscribe(res => {
        this.formControll.control.forEach(item => {
          if (item instanceof DynamicFormArray) {
            if (res[item.formName] != null) {
              this.form.controls[item.formName] = this.fb.array([])
              this.loadForm(res[item.formName], item, this.form)
            }
          }
        });
        this.form.patchValue(res)
        this.eventEmmit.emit({ name: "inicial_load", item: null, form: this.form })
      });
    }
  }
  markAllDirty(control: AbstractControl) {
    if (control.hasOwnProperty('controls')) {
      control.markAsDirty() // mark group
      control.markAsTouched() // mark group
      let ctrl = <any>control;
      for (let inner in ctrl.controls) {
        this.markAllDirty(ctrl.controls[inner] as AbstractControl);
      }
    }
    else {
      (<FormControl>(control)).markAsDirty();
      (<FormControl>(control)).markAsTouched();
    }
  }
  loadForm(data: [], item: DynamicFormArray, form: FormGroup) {
    if (data != null) {
      for (let line = 0; line < data.length; line++) {
        form.addControl(item.formName, this.fb.array([]));
        var formArray = form.controls[item.formName] as FormArray;
        formArray.setParent(form)
        var index = 0;
        var formArrayGroup = new FormGroup({});
        formArrayGroup.setParent(formArray);
        item.control.forEach(array => {
          if (array instanceof DynamicFormArray) {
            this.loadForm(data[line][array.formName], array, formArrayGroup)
          } else {
            formArrayGroup.addControl(array.formName, this.constructorForm(array as DynamicFormControl));
          }
        });
        formArray.push(formArrayGroup);
      }
    }
  }

  parentEventReceived(event) {
  }
  private setTitle() {
    if (this.formControll.title)
      this.title.setTitle(this.formControll.title);

  }
  @Input()
  set formModelConstructor(value: DynamicForm) {
    this.formControll = value;
    if (value.control) {
      value.control.forEach(item => {
        if (item instanceof DynamicFormControl) {
          this.form.addControl(item.formName, this.constructorForm(item as DynamicFormControl));
        } else if (item instanceof DynamicFormArray) {
          this.form.addControl(item.formName, this.fb.array([]));
          var formArray = this.form.controls[item.formName] as FormArray;
          formArray.setParent(this.form)
          formArray.push(this.AddArray(item));
        }
      });
    }
    this.setTitle();
  }
  AddArray(item) {
    var index = 0;
    var formArrayGroup = new FormGroup({});
    item.control.forEach(array => {
      if (array instanceof DynamicFormControl) {
        formArrayGroup.addControl(array.formName, this.constructorForm(array as DynamicFormControl));
      } else {
        var arrayitem = this.fb.array([])
        arrayitem.push(this.AddArray(array))
        formArrayGroup.addControl(array.formName, arrayitem)
      }
    });
    return formArrayGroup
  }
  CKT(control): string {
    if (control instanceof DynamicFormArray) {
      return "DFA";
    } else {
      return "DFC";
    }

  }
  formSize(element) {
    var columns = 'col-lg-' + element.columns;
    return columns
  }

  eventEmmitFunc(event) {
    if (event.type != undefined) {
      this.eventEmmit.emit({ name: event.name, type: event.type, item: event.item, form: this.form })
    } else {
      this.eventEmmit.emit({ name: event.name, item: event.item, form: this.form })
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      this.showErros = true
      return;
    }
    if (!this.formControll.urlPost) {
      this.salvar.emit(this.form.value);
    } else {
      this.http.post<any>(this.formControll.urlPost, this.form.value).subscribe(
        res => {
          if (res || res.status) {
            this.salvar.emit(res);
          } else {
            if (res == false) {
              alert("Ocorreu um erro");
            } else {
              alert(res)
            }
          }
        },
        error => {
          alert(error.error.text);
        }
      );
    }
  }

  renderDynamicFormControl(control): DynamicFormControl[] | DynamicFormArray {
    var controls = [];
    control.forEach(item => {
      if (item.visible || (this.CKT(item) == "DFA" && item.visible))
        controls.push(item)
    });
    return controls;
  }

  onCancelar() {
    if (this.form.dirty) {
      if (confirm('Deseja Cancelar as Alterações?')) {
        this.cancelar.emit();
      }
    } else
      this.cancelar.emit();
  }
  private constructorForm(control: DynamicFormControl): FormControl {
    const validators: ValidatorFn[] = [];
    if (control.required) { validators.push(Validators.required); }
    if (control.isId) {
      return new FormControl(control.value || 0, validators);
    }
    if (control.type == TypeField.DropDown || control.type == TypeField.permissionSelect) {
      if (control.required)
        validators.push(Validators.min(1))
      return new FormControl(control.value, validators);
    }
    if(control.type == TypeField.number || control.type == TypeField.decimal){
      var formControll = new FormControl(control.value || 0 , validators);
      if (control.type == TypeField.decimal ) {
        formControll.setValue("0.0");
      } else {
        formControll.setValue("0");
      }
      return formControll;
    }
    if (control.type == TypeField.switch) {
      return new FormControl(control.value || false, validators);
    }
    if (control.type == TypeField.currency) {
      if (control.required){
        validators.push(Validators.min(0))
      }else{
        var formControll=  new FormControl(control.value || 0, validators);
        formControll.setValue("0.0");
        return formControll;
      }
      return new FormControl(control.value || 0, validators);
    }
    return new FormControl(control.value || '', validators);
  }
}