import { Component, Input, Output, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';
import { IfStmt } from '@angular/compiler';

// text,email,tel,textarea,password,
@Component({
  selector: 'currency-box',
  template: `<div
    [formGroup]="form">
        <input autocomplete="off" maxlength=""
        [formControl]="myControl"
            class="form-control"
            [id]="control.formName"
            [attr.disabled]="!control.visible ? '' : null"
            placeholder="R$ 0,00" 
            [readonly]="control.readonly"
            (click)="ClearCamp()"
            (input)="setValue($event.target.value)">
</div>`,
  styleUrls: ['./inputs.scss']
})
export class CurrencyComponent implements OnInit {
  filteredOptions: Observable<any>;
  constructor() {
  }
  ngOnInit(): void {
    this.form.controls[this.control.formName].valueChanges.subscribe(value => { 
      this.Path(value.toString()) 
    })
  }
  ClearCamp(){
    if(!this.control.readonly){
      this.myControl.setValue(null)
    }
  }
  Path(value: string) {
    if (this.myControl.value == null &&  (value != "0" && value)) {
      var value = "R$"+this.currencyConverter(value).replace(".", ",");
      this.setValue(value)
    }
  }
  currencyConverter(value) {
    return parseFloat(value).toFixed(2);
  }
  myControl = new FormControl();
  @Input() @Output() control: DynamicFormControl;
  @Input() @Output() form: FormGroup;

  setValue(event) {
    var value = this.ClearZero(event)
    if (value == "")
      value = "00"
    var curency = ""
    var rValue = value.split('').reverse()
    if (rValue.length >= 3) {
      if (rValue.length >= 9)
        rValue = rValue.slice(0, 9)
      curency = rValue[0] + rValue[1] + ","
      rValue = rValue.slice(2, rValue.length)
      var count = 0;
      for (let number of rValue) {
        if (count == 3) {
          curency += ".";
          count = 0;
        }
        curency += number,
          count += 1;
      }
      var SCurrency = curency.split('').reverse().join('');
      this.myControl.setValue("R$" + SCurrency);
      this.SetValue(SCurrency);

    } else {
      if (value == 0 || value == "")
        this.myControl.setValue("R$0,00")
      this.myControl.setValue("R$0," + value)
    }
  }
  SetValue(value) {
    while (value.includes("."))
      var value = value.replace(".", "");
    value = value.replace(",", "")
    this.form.controls[this.control.formName].setValue(value / 100)
  }
  ClearZero(value) {
    while (value.includes("."))
      var value = value.replace(".", "");
    value = value.replace("R$0000000", "")
    value = value.replace("R$000000", "")
    value = value.replace("R$00000", "")
    value = value.replace("R$0000", "")
    value = value.replace("R$000", "")
    value = value.replace("R$00", "")
    value = value.replace("R$0", "")
    value = value.replace("R$", "")
    value = value.replace(",", "")
    return value;
  }
}