import { DynamicFormControl } from "../dynamic-forms/dynamic-forms.model";

export class ListComponent {
    name: string;
    label: string;
  }
  export class botaoList {
    name: string;
    icon: string;
    loadButton:boolean|string;
   
  }
  export class DynamicListControl {
    title: string;
    createRoute: string;
    editRoute: string;
    urlGetData: string;
    delete:string;
    columns: ListComponent[];
    buttonsList?: botaoList[];
    messageAdd:string
    extraButton:string
    filtros:DynamicFormControl[]
    modalFilter:boolean
    headerFilterSize:number
    constructor(option:{
      title: string,
      createRoute: string,
      editRoute: string,
      urlGetData: string,
      delete:string,
      columns: ListComponent[];
      messageAdd?:string
      buttonsList?: botaoList[]
      filtros?:DynamicFormControl[]
      extraButton?:string;
      headerFilterSize?:number
      modalFilter?:boolean
    }){
      this.extraButton = option.extraButton;
      this.messageAdd = option.messageAdd;
      this.delete = option.delete;
      this.title = option.title
      this.createRoute= option.createRoute
      this.editRoute= option.editRoute
      this.urlGetData= option.urlGetData
      this.columns= option.columns
      this.buttonsList = option.buttonsList
      this.filtros = option.filtros
      this.headerFilterSize = option.headerFilterSize||4
      this.modalFilter = option.modalFilter 
    }
  }
  export class Pagination {
    pageSize: number;
    pageIndex: number;
    column: string;
    direction: string;
  }