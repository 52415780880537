import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './Atom/layout/layout.component'
import { IndexGuardService } from './Auth/index-guard.service';
import {DevelopmentModule} from './Dev/development.module';

import { AuthRoleGuardService } from './Auth/auth-role.service';
import { LoginComponent } from './Admin/Account/login/login.component';
import { LogoutComponent } from './Admin/Account/logout/logout.component';
import { UserDataComponent } from './Admin/Account/user-data/user-data.component';
const routes: Routes = [
  { path: '', redirectTo: '/index', pathMatch: 'full' },
  { path: 'index', canActivate: [IndexGuardService], component: LayoutComponent, },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'me', component: UserDataComponent },
  {
    path: 'dev',
    component: LayoutComponent,
    loadChildren: './Dev/development.module#DevelopmentModule',  //'./Dev/development.module#DevelopmentModule',
    canActivate: [AuthRoleGuardService],
    data: {
      expectedRole: 'DEV',
    }
  },
  {
    path: 'administrativo',
    component: LayoutComponent,
    loadChildren: './Admin/Admin.module#AdminModule',  //'./Dev/development.module#DevelopmentModule',
    canActivate: [AuthRoleGuardService],
    data: {
      expectedRole: 'ADMIN',
    }
  },
  {
    path: 'atendimento',
    component: LayoutComponent,
    loadChildren: './Central/Central.module#CentralModule',  //'./Dev/development.module#DevelopmentModule',
    canActivate: [AuthRoleGuardService],
    data: {
      expectedRole: 'CENTRAL',
    }
  },
  {
    path: 'servicos',
    component: LayoutComponent,
    loadChildren: './PrefeituraServicos/prefeitura.module#PrefeituraModule',  //'./Dev/development.module#DevelopmentModule',
    canActivate: [AuthRoleGuardService],
    data: {
      expectedRole: 'SERVICO',
    }
  },
  {
    path: 'contratante',
    component: LayoutComponent,
    loadChildren: './Contratante/contratante.module#ContratanteModule',  //'./Dev/development.module#DevelopmentModule',
    canActivate: [AuthRoleGuardService],
    data: {
      expectedRole: 'CONTRATANTE',
    }
  },
  /*{
    path: 'loja',
    component: LayoutComponent,
    loadChildren: './Loja/loja.module#LojaAppModule',  //'./Loja/loja.module#LojaAppModule',
    canActivate: [AuthRoleGuardService],
    data: {
      expectedRole: 'LojaUser',
    }
  },*/
  /*
  {
    path: 'func',
    component: LayoutComponent,
    loadChildren: './func/func.module#FuncModule',
    canActivate: [AuthRoleGuardService],
    data: {
      expectedRole: 'ClienteUsuario',
    }
  },
 
 
  { path: '------', redirectTo: '/--/-/', data: { icon: { icon: 'home', status: true }, url: '', nome: '' } }*/
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }