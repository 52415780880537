import { Component, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';

// text,email,tel,textarea,password,
@Component({
    selector: 'celphone-number-box',
    template: `<div
        [formGroup]="form">
        <input placeholder="Ex: 49 99999-9999"
        class="form-control"
        [id]="control.formName"
        [formControlName]="control.formName"
        [attr.disabled]="!control.visible ? '' : null"
        [textMask]="{mask: phoneNumberMask}" type="tel"
        [readonly]="!control.readonly ? '' : null">
    </div>`,
    styleUrls: ['./inputs.scss']
})
export class CelphoneNumberComponent {
    @Input() @Output() control: DynamicFormControl;
    @Input() @Output() form: FormGroup;
    phoneNumberMask = phoneNumberMask();
}
export function phoneNumberMask() {
    return [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    
    //pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
  }