import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DynamicFormControl, DynamicItens, TypeField } from '../dynamic-forms/dynamic-forms.model';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { startWith, map } from 'rxjs/operators';
// text,email,tel,textarea,password,
@Component({
  selector: 'comorbidade-box',
  template: `
<div style="display:flex">
  <div class="control appSelect" style="display: flex;width: -webkit-fill-available;" [ngClass]=" { 'disabled opac': controller.readonly  }">
    <mat-form-field >
    <div class="d-flex">
      <input type="text"
               class="form-control"
              placeholder=""
              aria-label="Number"
              matInput
              [formControl]="myControl"
              [matAutocomplete]="auto"
              (click)="changeValue()">
              <span  *ngIf="form.controls[this.controller.formName].valid && form.controls[this.controller.formName].value !='0'" (click)="changeValue()" class="icon-rd-bd-confirm mr-1" style="align-self: center; color:green;"></span>
              <span class="icon-botton-arrow-sign mx-2" style="align-self: center;" (click)="changeValue()"></span>
    </div>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onChange($event.option.value)" >
        <mat-option *ngFor="let option of this.filteredOptions | async" [value]="option.nome">
            {{option.nome}}
        </mat-option>
        
        <mat-option *ngIf="size==0" [value]="0" >
        Nenhuma informação encontrada
      </mat-option>
      </mat-autocomplete>
      
    </mat-form-field>   
  </div>
    <popover-content #myPopover [closeOnClickOutside]="true" style="top: 50px" >
      <div class="m-1" [innerHTML]="help"></div>
    </popover-content>
    <span  style="align-self: center;" >
      <mat-icon [popover]="myPopover" [popoverOnHover]="false" 
        class="mr-1" style="font-size: 25px;color: #2ec4ab ;text-align: center;display: contents;">help</mat-icon>
    </span>
    
</div>
            `,
  styleUrls: ['./AutoComplete.component.scss']
})
export class ComorbidadeComponent implements OnInit {
  //help_outline
  @Input() @Output() controller: DynamicFormControl;
  @Input() @Output() form: FormGroup;
  @Output() autoSelect = new EventEmitter<any>();
  filteredOptions: Observable<ComorbidadeItens[]>;
  myControl = new FormControl();
  size = 1;
  constructor(private http: HttpClient) { }
  help = "<br>Selecione a Comorbidade para mais detalhes<br>"
  ngOnInit() {
    if (this.controller.urlGetItens)
      this.http.get<any[]>(this.controller.urlGetItens).subscribe(res => {
        this.controller.options = res
        var option = (this.controller.options.find(p => p.key == this.form.controls[this.controller.formName].value)) as ComorbidadeItens;
        if (option)
          this.myControl.setValue(option.nome)
      });
      this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    this.form.controls[this.controller.formName].valueChanges
      .subscribe(value => {
        if (!this.isrefresh) {
          this.myControl.patchValue((this.controller.options as ComorbidadeItens[]).find(p => p.key == value).nome)
          this.isrefresh = true
        }
      });
  }
  isrefresh = false;
  changeValue() {
    this.myControl.setValue('');
    this.help = "Selecione a Comorbidade para mais detalhes<br>"
    this.form.controls[this.controller.formName].setValue(null);
  }
  private _filter(value: string): ComorbidadeItens[] {
    if (this.controller.options && value != "0") {
      const filterValue = value.toLowerCase();
      var options = (this.controller.options as any[]).filter(option => (option.nome).toLowerCase().includes(filterValue));
      this.size = options.length;
      return options;
    }
  }
  onChange(option) {
    if (option) {
      var _optionItem = (this.controller.options as ComorbidadeItens[]).find(p => p.nome == option);
      this.form.controls[this.controller.formName].setValue(_optionItem.key);
      this.help = _optionItem.description
      this.autoSelect.emit({ name: this.controller.formName, item: _optionItem.key })
    } else {
      this.form.controls[this.controller.formName].setValue(null);
      this.myControl.setValue('');
    }
  }
}
export class ComorbidadeItens extends DynamicItens {
  key: any;
  description: any;
  nome: any
}