import { Component, OnInit, Renderer2, Output, EventEmitter, Input, HostBinding } from '@angular/core';
import { HttpClient } from 'node_modules/@angular/common/http';
import { Router, NavigationEnd } from 'node_modules/@angular/router';
import { AuthService } from 'src/app/Auth/auth.service';
import { SideMenu, MenuItem } from 'src/app/side-menu';
@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  expanded: boolean;
  @Input() item: MenuItem;
  @Input() depth: number;
  constructor(private router: Router,) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
   }
  showMenu: string;
  ngOnInit() {
    this.showMenu = '';
  }
  paddingItem(deph){
    return {'padding-left': (deph * 5) + 5 + 'px'}
  }
  StyleItem(styleClass){
    if(styleClass == null || styleClass== undefined){
      return 'spanTitle';
    }else{
      return styleClass+'spanTitle'
    }
  }
  addExpandClass(element: any) {
    
    if (element === this.showMenu) {
      this.showMenu = '';
    } else {
      this.showMenu = element;
    }
  }
  onItemSelected(item: MenuItem,isIcon:boolean) {
    if (!item.itens || !item.itens.length) {
     
    }
    if(item.url!="" && !isIcon){
      this.router.navigate([item.url]);
    }else if (item.itens && item.itens.length) {
      item.open = !item.open;
    }
  }
  navigate(route) {
    this.router.navigate([route]);
  }
}
