import { Component, OnInit, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../../dynamic-forms/dynamic-forms.model';
import { ImageModel } from '../image.component';

@Component({
  selector: 'app-triple-image-product',
  templateUrl: './triple-image-product.component.html',
  styleUrls: ['./triple-image-product.component.scss']
})
export class TripleImageProductComponent implements OnInit {

  @Input() @Output() control: DynamicFormControl;
  @Input() @Output() form: FormGroup;
  internalForm: FormGroup;
  constructor(private fb: FormBuilder) {
    this.internalForm = this.fb.group({
      image1: {},
      image2: {},
      image3: {}
    })
  }
  RefreshFormMain = true;
  ngOnInit() {
    if (this.form.controls[this.control.formName].value) {
      this.internalForm.patchValue(this.form.controls[this.control.formName].value)
    }
    this.form.controls[this.control.formName].valueChanges
      .subscribe(async value => {
        if (this.RefreshFormMain) {
            this.internalForm.patchValue(value)
            this.RefreshFormMain = false
        }
      });
  }
  UpdateMainForm(image, destino) {
    if (destino == 1) {
      this.internalForm.controls.image1.setValue(image);
    } else if (destino == 2) {
      this.internalForm.controls.image2.setValue(image);
    } else if (destino == 3) {
      this.internalForm.controls.image3.setValue(image);
    }
    this.form.controls[this.control.formName].setValue(this.internalForm.value);
  }
  onUpload(event, number) {
    this.RefreshFormMain = false
    this.form.controls[this.control.formName].markAsDirty()
    const reader = new FileReader();
    if (event && event.length) {
      const [file] = event;
      reader.readAsDataURL(file);
      if (file.size <= 3145728) {
        if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {
          reader.onload = () => {
            var image = { imageItemId: 0, id: 0, name: file.name, extension: file.type, size: file.size, data: reader.result }
            this.UpdateMainForm(image,number)
          };
        } else {
          alert("Extensão não suportada. Optar por PNG, JPEG ou JPG")
        }
      } else {
        alert("Imagem muito grande. Escolher Imagens com ate 3MB")
      }

    }
  }
  remove(id) {
    if (id == 1) {
      this.internalForm.controls.image1.setValue({})
    } else if (id == 2) {
      this.internalForm.controls.image2.setValue({})
    } else if (id == 3) {
      this.internalForm.controls.image3.setValue({})
    }
    this.form.controls[this.control.formName].setValue(this.internalForm.value);
  }
} export class TripleImageModel {
  imageItemId: any
  id: any
  name: string;
  extension: string
  size: string
  data: any
  constructor(
    imageItemId: any,
    id: any,
    name: string,
    extension: string,
    size: string,
    data: any,
  ) {
    this.name = name || "";
    this.imageItemId = imageItemId || 0;
    this.id = id || 0;
    this.extension = extension || "";
    this.size = size || "";
    this.data = data || "";
  }
}
