import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IndexGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) { }

  canActivate(): boolean {
    if (this.auth.isAuthenticated()) {
      if (this.auth.user.role === "ADMIN") {
        this.router.navigate(['/administrativo/index'])
        return true;
      } else if (this.auth.user.role === 'CENTRAL') {
        this.router.navigate(['/atendimento/index']);
        return true;
      } else if (this.auth.user.role === 'ESTABELECIMENTO') {
        this.router.navigate(['/estabelecimento/index']);
        return true;
      } else if (this.auth.user.role === 'CONTRATANTE') {
        this.router.navigate(['/contratante/index']);
        return true;
      }else if (this.auth.user.role === 'SERVICO') {
        this.router.navigate(['/servicos/index']);
        return true;
      }
      else if (this.auth.user.role === 'DEV') {
        this.router.navigate(['/dev/index']);
        return true;
      }
    }
    this.router.navigate(['/login']);
    return false;
  }
} 