import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/Auth/auth.service';
import { DynamicForm, DynamicFormControl, TypeField, DynamicFormArray } from 'src/app/Atom/dynamic-forms/dynamic-forms.model';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss']
})
export class UserDataComponent implements OnInit {
  form = new DynamicForm({
    urlget: "api/account/form",
    urlPost: "api/account/form",
    title: "Dados De Usuário",
    control: [
      new DynamicFormControl({ formName: "accessId", label: "AccessId", isId: true, type: TypeField.Text, visible: false }),
      new DynamicFormControl({ formName: "addressId", label: "AddressId", isId: true, type: TypeField.Text, visible: false }),
      new DynamicFormControl({ formName: "personId", label: "PersonId", isId: true, type: TypeField.Text, visible: false }),

      new DynamicFormControl({ formName: "username", columns: 4, label: "Usuario", type: TypeField.Text, value: "", readonly: true }),
      new DynamicFormControl({ formName: "password", columns: 4, label: "Senha de Acesso", type: TypeField.password, readonly: true }),
      new DynamicFormControl({ formName: "name", columns: 4, label: "Nome", type: TypeField.Text, value: "", placeHolder: "Fulano de Souza", required: true }),
      new DynamicFormControl({ formName: "mail", columns: 4, label: "Email", type: TypeField.Text, value: "", required: true }),
      new DynamicFormControl({ formName: "fone", columns: 4, label: "Telefone", type: TypeField.phone, value: "" }),
      new DynamicFormControl({ formName: "cpf", columns: 4, label: "CPF", type: TypeField.cpf, value: "" }),

      new DynamicFormControl({ formName: "cep", columns: 2, label: "CEP", type: TypeField.cep, value: "" }),
      new DynamicFormControl({ formName: "street", columns: 4, label: "Rua", type: TypeField.Text, value: "", readonly: true }),
      new DynamicFormControl({ formName: "number", columns: 2, label: "Numero", type: TypeField.number, value: "0", readonly: true }),
      new DynamicFormControl({ formName: "district", columns: 4, label: "Bairro", type: TypeField.Text, value: "", readonly: true }),
      new DynamicFormControl({ formName: "city", columns: 4, label: "Cidade", type: TypeField.Text, value: "", readonly: true }),
      new DynamicFormControl({ formName: "state", columns: 2, label: "Estado", type: TypeField.Text, value: "", readonly: true }),
      new DynamicFormControl({ formName: "complement", columns: 6, label: "Complemento", type: TypeField.Text, value: "", readonly: true }),
    ]
  });
  constructor(
    public auth: AuthService,
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<UserDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  eventReceive(event) {
    var form: FormGroup = event.form;
    if (event.name == "CEP") {
      var street = this.form.control.find(p => p.formName == "street") as DynamicFormControl
      if (event.item.street == "") {
        street.readonly = false;
      } else {
        street.readonly = true;
        form.controls[street.formName].setValue(event.item.street)
      }
      var district = this.form.control.find(p => p.formName == "district") as DynamicFormControl
      if (event.item.district == "") {
        district.readonly = false;

      } else {
        district.readonly = true;
        form.controls[district.formName].setValue(event.item.district)
      }
      var city = this.form.control.find(p => p.formName == "city") as DynamicFormControl
      if (event.item.city == "") {
        city.readonly = false;
      } else {
        city.readonly = true;
        form.controls[city.formName].setValue(event.item.city)
      }
      var state = this.form.control.find(p => p.formName == "state") as DynamicFormControl
      if (event.item.state == "") {
        state.readonly = false;
      } else {
        state.readonly = true;
        form.controls[state.formName].setValue(event.item.state)
      }
      (this.form.control.find(p => p.formName == "complement") as DynamicFormControl).readonly = false;

    }
  }
  cancelar(event) {
    this.dialogRef.close()
  }
  salvar(item) {
    alert("Salvo Com Sucesso")
    this.dialogRef.close()
  }

  ngOnInit() { }
}
