export class FormBase {
  label: string;
  formName: string;
}
export class DynamicFormControl extends FormBase {
  type: TypeField;
  value: any;
  isId: boolean;
  columns: number;
  required: boolean;
  options: DynamicItens[] | string;
  urlGetItens?: string;
  visible: boolean
  placeHolder: string;
  readonly: boolean
  help: string
  constructor(options: {
    type: TypeField;
    placeHolder?: string;
    formName: string;
    label: string;
    value?: any;
    columns?: number;
    readonly?: boolean;
    required?: boolean;
    options?: DynamicItens[] | string;
    urlDrop?: string;
    visible?: boolean;
    isId?: boolean;
    help?: string;
  }) {
    super();
    this.help = options.help;
    this.columns = options.columns || 2;
    this.type = options.type;
    this.formName = options.formName;
    this.label = options.label;
    this.value = options.value || "";
    this.required = options.required || false;
    this.options = options.options || "";
    this.urlGetItens = options.urlDrop || "";
    this.visible = (options.visible != null) ? options.visible : true;
    this.placeHolder = options.placeHolder || "";
    this.readonly = options.readonly || false;
    this.isId = options.isId || false;
  }
}
export class DynamicForm {
  urlget: string;
  urlPost: string;
  title: string;
  subTitle: string
  control: FormBase[];
  internalHeader: boolean;
  unlockValid: boolean;
  Save: string;
  constructor(options: {
    urlget: string;
    urlPost: string;
    control: FormBase[];
    title: string;
    internalHeader?: boolean
    unlockValid?: boolean
    subTitle?: string
    Save?: string
  }) {
    this.subTitle = options.subTitle;
    this.control = options.control;
    this.urlPost = options.urlPost;
    this.urlget = options.urlget;
    this.title = options.title;
    this.internalHeader = options.internalHeader || false;
    this.unlockValid = options.unlockValid || false;
    this.Save = options.Save || "Salvar";
  }
}

export class DynamicFormArray extends FormBase {
  control: FormBase[]
  columns: number;
  internal: number
  CanAdd: boolean;
  CanRemove: boolean;
  CanClone: boolean;
  opaque: boolean;
  MaxArraySize: number;
  help: string
  buttonAdd: boolean;
  MinItens: number;
  visible:boolean;
  constructor(options: {

    label: string,
    formName: string,
    control: FormBase[],
    columns?: number,
    CanAdd?: boolean,
    CanRemove?: boolean,
    CanClone?: boolean,
    MaxArraySize?: number,
    internal?: number,
    opaque?: boolean,
    help?: string,
    buttonAdd?: boolean,
    MinItens?: number
    visible?: boolean;
  }) {
    super();
    this.buttonAdd = options.buttonAdd
    this.help = options.help;
    this.opaque = options.opaque;
    this.control = options.control;
    this.formName = options.formName;
    this.label = options.label;
    this.columns = options.columns || 12;
    this.CanAdd = options.CanAdd;
    this.CanClone = options.CanClone;
    this.CanRemove = options.CanRemove;
    this.MaxArraySize = options.MaxArraySize || 99;
    this.internal = options.internal || 12
    this.MinItens = options.MinItens 
    this.visible = (options.visible != null) ? options.visible : true;
  }
}
export enum TypeField {
  Text = 1,
  DropDown = 2,
  phone = 3,
  data = 4,
  decimal = 5,
  password = 6,
  cnpj = 7,
  cpf = 8,
  divider = 9,
  cep = 10,
  number = 11,
  image = 12,
  switch = 13,
  permissionSelect = 14,
  timePicker = 15,
  branchPicker = 16,
  autocomplete = 17,
  e_autocomplete = 18,
  color_select = 19,
  productImage = 20,
  currency = 21,
  TextArea = 22,
  comorbidades = 23,
  datetime = 24,
  autocomplete_add = 25,
  datestring = 26,
  hourstring = 27,
  File = 28,
  FileBrute = 29,

}
export class DynamicItens {
  key: any;
  description: any;
}