import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() active:boolean
  @Input() size:Number = 50 ;
  
  constructor() { }

  ngOnInit() {
  }
  loadStyle(){ 
      return this.size+"px";
  }
}
