import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { DynamicFormArray, DynamicItens, DynamicFormControl, } from '../../dynamic-forms.model';

@Component({
  selector: 'app-form-array-builder',
  templateUrl: './form-array-builder.component.html',
  styleUrls: ['./form-array-builder.component.scss']
})
export class FormArrayBuilderComponent implements OnInit {
 
  @Input() control: DynamicFormControl;
  @Input() formArrayName: string;
  @Input() form: FormGroup;
  @Output() eventEmmit = new EventEmitter<any>();
  listOptionsCache: DynamicItens[];
  @Input() showErros: boolean;
  constructor() { }

  get formArrayItem(): FormArray {
    return <FormArray>this.form.get(this.control.formName);
  }
  ngOnInit() {
  }
  
 get isDirty() { return this.form.controls[this.control.formName].dirty; }
  get isValid() {
      const controlForm = this.form.get(this.control.formName);
      const erros = controlForm.errors;
      let hasErros = false;
      if (erros) {
        Object.keys(erros).forEach(function (key, index) {
          hasErros = controlForm.hasError(key);
          if (hasErros) {
            return;
          }
        });
      }
      return !hasErros;
  }
  //Validator (error and Dirty)

}
