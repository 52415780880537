
import { Component, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';

// text,email,tel,textarea,password,
@Component({
    selector: 'cnpj-box',
    template: `<div
    [formGroup]="form">
        <input placeholder="Ex: 99.999.999/9999-99"
            class="form-control"
            [id]="control.formName"
            [formControlName]="control.formName"
            [attr.disabled]="!control.visible ? '' : null"
            [textMask]="{mask: cnpjMask}" type="tel"
            [readonly]="control.readonly">
</div>`,
styleUrls: ['./inputs.scss']
})
export class CNPJComponent  {
    @Input() @Output() control: DynamicFormControl;
    @Input() @Output() form: FormGroup;
    cnpjMask = cnpjMask();
}
export function cnpjMask() {
    return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  }