import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-color-circle',
  templateUrl: './color-circle.component.html',
  styleUrls:['./color-circle.component.scss']
})
export class ColorCircleComponent implements OnInit {

  @Input() cor1: string
  @Input() cor2: string
  constructor() { }

  ngOnInit() {


  }
  color(part):object  {
    if (part == 1 || part == 2) {
      if (this.cor1) {
        return {"background":this.cor1}
      } else {
        return {"background":this.cor2}
      }
    }else{
      if (this.cor2) {
        return {"background":this.cor2}
      } else {
        return {"background":this.cor1}
      }
    }

  }
}
