import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';
import { HttpClient } from '@angular/common/http';


// text,email,tel,textarea,password,
@Component({
    selector: 'cep-box',
    template: `<div
    [formGroup]="form">
    
        <input placeholder="Ex: 99999-99" 
            (ngModelChange)="CepGetData($event)"
            class="form-control "
            [ngClass]="{ 'loading': onLoad }"
            [id]="control.formName"
            [formControlName]="control.formName"
            [attr.disabled]="!control.visible ? '' : null"
            [textMask]="{mask: CepMask}" type="tel"
            [readonly]="!control.readonly ? '' : null">
</div>`,
styleUrls: ['./inputs.scss']
})
export class CEPComponent {
    @Input() @Output() control: DynamicFormControl;
    @Input() @Output() form: FormGroup;
    @Output() cep = new EventEmitter<any>();
    onLoad:boolean = false;
    CepMask = CepMask();
    constructor(private http: HttpClient) { }
    CepGetData(event) {
        if (event && event[8] != "_"){
            this.http.get<any>("api/validate/cep/" + event).subscribe(
                res => {
                    this.onLoad = false;
                    if (res.status)
                        this.cep.emit({name: "CEP",item:res.data})
                    else {
                        alert(res.error)
                    }
                }
            )
        }
    }
}
export function CepMask() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
}