import { Component, Input, Output, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';
import { HttpClient } from '@angular/common/http';

// text,email,tel,textarea,password,
@Component({
  selector: 'file-box',
  template: `
  <div class="d-flex">
    <div class="custom-file Add" [formGroup]="form">
      <input type="file" 
        class=" custom-file-input  "
        [id]="control.formName"
        [readonly]="!control.readonly ? '' : null"
        [attr.disabled]="!control.visible ? '' : null"
        (change)="onUpload($event.target.files)"
      >
      <label class="custom-file-label" [for]="control.formName">{{FileName}}</label>
    </div>
    <!--<span *ngIf="control.value" class="icon-show-hide eye-file" (click)="openFile()"></span>-->
  </div>

  `,
  styleUrls: ['./inputs.scss']
})
export class FileComponent implements OnInit {
  ngOnInit(): void {
    var value = this.form.controls[this.control.formName].value
    this.control.value = value;
    this.form.get(this.control.formName).valueChanges.subscribe(value => {
      if (value != null)
        this.control.value = value
    }
    );
  }
  FileName = "Selecione o Arquivo"
  @Input() @Output() control: DynamicFormControl;
  @Input() @Output() form: FormGroup;
  constructor(private http: HttpClient) {

  }
  ngOnChange() {

  }
  remove() {
    this.control.value = null;
    this.form.controls[this.control.formName].setValue(null);
  }
  openFile() {
    let pdfWindow = window.open("")
    pdfWindow.document.write('<embed type="application/x-google-chrome-pdf" src="'+ this.control.value.data +'" stream-url="chrome-extension://mhjfbmdgcfjbbpaeojofohoefgiehjai/beaf4f2a-d16f-403e-ba9d-7f4fcc2613c9"'+ 
    'headers="Content-Type: application/pdf " background-color="0xFF525659" top-toolbar-height="56" javascript="allow" full-frame="">')
  }
  onUpload(event) {
    const reader = new FileReader();
    if (event && event.length) {
      const [file] = event;
      reader.readAsDataURL(file);
      reader.onload = () => {
        var arquivo = new FileModel(file.name, file.type, file.size, reader.result);
        this.control.value = arquivo;
        this.FileName = arquivo.name
        this.form.controls[this.control.formName].setValue(arquivo);
      };
    }
  }
}
export class FileModel {
  id: any;
  name: string;
  extension: string;
  size: string;
  data: any;
  constructor(
    name: string,
    extension: string,
    size: string,
    data: any
  ) {
    this.name = name || "";
    this.extension = extension || "";
    this.size = size || "";
    this.data = data || "";
  }
}