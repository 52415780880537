import { Component, Input, Output, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';
import { MatDatepickerInputEvent } from '@angular/material';
import { DatePipe } from '@angular/common';

// text,email,tel,textarea,password,
@Component({
    selector: 'date-box',
    template: `<div [formGroup]="form">
    <div class="input-group-date input-group">
        <input 
            placeholder="Ex: 09/08/1990"
            class="form-control"
            [id]="control.formName"
            [formControl]="myControl"
            [disabled]="!control.visible ? '' : null"
            [readonly]="control.readonly"
            [textMask]="{mask: dateMask}" type="tel"
            (input)="setaValue($event.target.value)"    >
            <input hidden [matDatepicker]="picker" [disabled]="control.readonly" [formControlName]="control.formName" (dateChange)="changeDate($event.value)">
            <mat-datepicker-toggle matSuffix  [for]="picker" style="height: 35px; display: flex;flex-flow: column-reverse;"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
    </div>
</div>`,
    styleUrls: ['./inputs.scss'],
    providers: [DatePipe]
})
export class DateComponent implements OnInit {
    @Input() @Output() control: DynamicFormControl;
    myControl = new FormControl()
    @Input() @Output() form: FormGroup;
    dateMask = dateMask();
    constructor(private datePipe: DatePipe) {
    }
    setaValue(event) {
        if (event[9] != "_") {
            while (event.includes("/")) {
                var event = event.replace("/", "");
            }
            var date = event[2] + event[3] + '-' + event[0] + event[1] + '-' + event[4] + event[5] + event[6] + event[7];
            let newDate = new Date(date);
            if (newDate.getTime() != NaN)
                this.form.controls[this.control.formName].setValue(newDate);
            
        }
    }
    ngOnInit(): void {
        this.form.controls[this.control.formName].valueChanges.subscribe(value => { this.Path(value.toString()) })
    }
    Path(value: string) {
        if (this.myControl.value == null && this.form.controls[this.control.formName].value != "0001-01-01T00:00:00") {
            this.changeDate(new Date(this.form.controls[this.control.formName].value))
        }
    }
    changeDate(event: Date) {
        this.myControl.setValue(this.datePipe.transform(event, 'dd/MM/yyyy'))
    }
    ValidData(value: string) {
        if (value.length == 1) {
            return "0" + value;
        }
    }
}
export function dateMask() {
    return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
}