import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationStart } from 'node_modules/@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  
  subscription: Subscription

  // Example in the constructor of you App Component
  constructor() {
    /*this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });*/
  }
}
