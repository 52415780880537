import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './Atom/menu/menu.component';
import { LayoutComponent } from './Atom/layout/layout.component';
import { UserDropComponent } from './Atom/header/user-drop/user-drop.component';
import { UserNotificationComponent } from './Atom/header/user-notification/user-notification.component';
import { HeaderComponent } from './Atom/header/header.component';
import { AmChartsModule } from 'node_modules/@amcharts/amcharts3-angular/src';
import { TextMaskModule } from 'node_modules/angular2-text-mask/dist/angular2TextMask';
import { AppRoutingModule } from './app-routing.module';
import { DynamicListComponent } from './Atom/dynamic-list/dynamic-list.component';
import { DynamicFormComponent } from './Atom/dynamic-forms/dynamic-forms.component';
import { CEPComponent } from './Atom/Inputs/CEP.component'
import { CurrencyComponent } from './Atom/Inputs/currency.component'
import { CNPJComponent } from './Atom/Inputs/cnpj.component'
import { CPFComponent } from './Atom/Inputs/cpf.component'
import { DateComponent } from './Atom/Inputs/data.component'
import { NumberComponent } from './Atom/Inputs/number.component'
import { DecimalComponent } from './Atom/Inputs/decimal.component'
import { DividerComponent } from './Atom/Inputs/divider.component';
//import { } from './Atom/Inputs/image.component'
import { PasswordComponent } from './Atom/Inputs/password.component'
import { CelphoneNumberComponent } from './Atom/Inputs/phone.component'
import { SelectBoxComponent } from './Atom/Inputs/select.component'
import { TextComponent } from './Atom/Inputs/text.component'
import { FormBuilderComponent } from './Atom/dynamic-forms/form-builder/form-builder.component';
import { FormArrayComponent } from './Atom/dynamic-forms/form-array/form-array.component';
import { ImageComponent } from './Atom/Inputs/image.component';
import { FormArrayBuilderComponent } from './Atom/dynamic-forms/form-array/form-array-builder/form-array-builder.component';
import { SliderComponent } from './Atom/Inputs/slider.component';
import { TimePickerComponent } from './Atom/Inputs/timepicker.component';
import { SliderButtonComponent } from './Atom/dynamic-forms/components/slider-button/slider-button.component';
import { HourSelectComponent } from './Atom/dynamic-forms/components/hour-select/hour-select.component';
import { AutoCompleteBoxComponent } from './Atom/Inputs/AutoComplete.component';
import { ColorCircleComponent } from './Atom/Inputs/color-select/color-circle/color-circle.component';
import { ColorSelectBoxComponent } from './Atom/Inputs/color-select/color-select.component';
import { TripleImageProductComponent } from './Atom/Inputs/triple-image-product/triple-image-product.component';
import { TextAreaComponent } from './Atom/Inputs/text-area.component';
import { ComorbidadeComponent } from './Atom/Inputs/Comorbidades.component';
import { DateTimeComponent } from './Atom/Inputs/datetime.component';
import { DateStringComponent } from './Atom/Inputs/date.string.component';
import { HourStringComponent } from './Atom/Inputs/hour.string.component';
import { MultiSelectComponent } from './Atom/Inputs/multi-select/multi-select.component';
import { SpinnerComponent } from './Atom/spinner/spinner.component';
import { FileComponent } from './Atom/Inputs/file.component.';
import { MenuItemComponent } from './Atom/menu/menu-item/menu-item.component';
import { FiltrosHeaderComponent } from './Atom/dynamic-list/filtros-header/filtros-header.component';
import { FiltrosModalComponent } from './Atom/dynamic-list/filtros-modal/filtros-modal.component';
import { FileBruteComponent } from './Atom/Inputs/file-brute.component.';
import { UserDataComponent } from './Admin/Account/user-data/user-data.component';

@NgModule({
    declarations: [
        UserDataComponent,
        CEPComponent,
        TimePickerComponent,
        CurrencyComponent,
        CNPJComponent,
        CPFComponent,
        DateComponent,
        DecimalComponent,
        PasswordComponent,
        CelphoneNumberComponent,
        SelectBoxComponent,
        TextComponent,
        DividerComponent,
        DynamicListComponent,
        DynamicFormComponent,
        SliderComponent,
        NumberComponent,
        ImageComponent,
        FormBuilderComponent,
        FormArrayComponent,
        FormArrayBuilderComponent,
        SliderButtonComponent,
        HourSelectComponent,
        AutoCompleteBoxComponent,
        ColorCircleComponent,
        ColorSelectBoxComponent,
        TripleImageProductComponent,
        TextAreaComponent,
        ComorbidadeComponent,
        DateTimeComponent,
        DateStringComponent,
        HourStringComponent,
        MultiSelectComponent,
        SpinnerComponent,
        FileComponent,
        FiltrosHeaderComponent,
        FiltrosModalComponent,
        FileBruteComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        TextMaskModule
    ], exports: [
        DynamicListComponent,
        DynamicFormComponent,
        FormArrayComponent,
        HourSelectComponent,
        SliderButtonComponent,
        UserDataComponent,

    ], entryComponents: [UserDataComponent,FiltrosModalComponent]
})
export class AtomModule { }