import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, EmailValidator } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Criptografy } from 'src/app/Auth/criptografy.service';
import { AuthService } from 'src/app/Auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showErro = false;
  Aguarde = false;
  form: FormGroup;

  showPassword = false;
  type = "password"

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, title: Title) {
    title.setTitle('Login');
    this.form = this.fb.group({
      email: ['', [Validators.required, EmailValidator]],
      password: ['', Validators.required]
    });
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/index']);
    }
  }
  showPasswordFunc(){
    if(this.showPassword){
      this.showPassword = false;
      this.type = "password"
    }else{
      this.showPassword = true;
      this.type = "text"
    }
  }

  async onSubmit() {
    this.Aguarde = true;
    this.showErro = false;
    if (this.form.valid) {
      const val = this.form.value;
      (await this.authService.login(val.email, val.password)).subscribe(
        res => {
          //this.authService.getToken();
          this.Aguarde = false;
          if (this.authService.isAuthenticated()) {
              this.router.navigate(['/index']);
          } else {
            this.showErro = true
          }
        }, error => {
          this.Aguarde = false;
          this.showErro = true
        }
      );
    } else {
      alert('Login incompleto. Preencha todos os campos!!');
    }
  }

  ngOnInit() {
  }


}