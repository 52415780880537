import { Component, OnInit, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { AuthService } from 'src/app/Auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  @Output() menuCollapse = new EventEmitter<any>();
  public pushRightClass: string;
  constructor(
    public auth:AuthService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    public title: Title) {
    this.router.events.subscribe((val) => {
      if (window.innerWidth <= 992 && this.isToggled()) {
        this.toggleSidebar();
      }
    });
    this.pushRightClass = 'push-right';
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  badge = 0;
  clearAll = false;
  caracterLimit = 15;
  mobileQuery: MediaQueryList;
  notification = false;
  private _mobileQueryListener: () => void;

  shouldRun = true; // [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  atualizaBagde(event) {
    this.badge = event;
  }

  login() {
    this.router.navigate(['login']);
  }

  logout() {
    this.auth.logout();
    this.login();
  }

  userAccount() {
    this.router.navigate(['account/user']);
  }

  userPassword() {
    this.router.navigate(['account/user/change-password']);
  }
  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

}