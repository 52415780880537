import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { mergeMap, finalize, map } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService, private router: Router, private dialog: MatDialog) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    if (request.url !== '/connect/token') {

      if (this.auth.isAuthenticated()) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token.token}`
          }
        });
        return next.handle(request).pipe();
      } else {
        this.router.navigate(['login']);
      }
    }else{
      return next.handle(request).pipe();
    }
  }
}