import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-slider-button',
  templateUrl: './slider-button.component.html',
  styleUrls: ['./slider-button.component.scss']
})
export class SliderButtonComponent implements OnInit {
  @Input() @Output() form: FormGroup;
  @Input() formName: string;
  @Output() change = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

}
