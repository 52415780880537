import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DynamicFormControl, DynamicItens, TypeField } from '../dynamic-forms/dynamic-forms.model';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { startWith, map, pairwise, retry, delay, repeat } from 'rxjs/operators';
// text,email,tel,textarea,password,
@Component({
  selector: 'auto-complete-box',
  template: `
  <div style="display:flex">
    <div class="control appSelect" style="display: flex;width: -webkit-fill-available;" [ngClass]=" { 'disabled opac': controller.readonly  }">
      <mat-form-field >
      <div class="d-flex">
        <input type="text"
                class="form-control"
               placeholder=""
               aria-label="Text"
               matInput
               [formControl]="myControl"
               [matAutocomplete]="auto"
               (click)="changeValue()">
               <span *ngIf="form.controls[this.controller.formName].valid && form.controls[this.controller.formName].value !='0'" (click)="changeValue()" class="icon-rd-bd-confirm mr-1" style="align-self: center; color:green;"></span>
               <span class="icon-botton-arrow-sign" style="align-self: center;" (click)="changeValue()"></span>
               </div>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onChange($event.option.value)" >
          <mat-option *ngFor="let option of this.filteredOptions | async" [value]="option.description" >
            {{option.description}}
          </mat-option>
          <mat-option *ngIf="size==0" [value]="0" >
          Nenhuma informação encontrada
        </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div style="align-self: center;margin-left: 8px;">
      </div>  
    </div>
    <span *ngIf="isAdd()" 
      class="icon-rd-bd-add icon-add bold" (click)="eventAdd()">
    </span>
  </div>
            `,
  styleUrls: ['./AutoComplete.component.scss']
})
export class AutoCompleteBoxComponent implements OnInit {

  @Input() @Output() controller: DynamicFormControl;
  @Input() @Output() form: FormGroup;
  @Output() autoSelect = new EventEmitter<any>();
  filteredOptions: Observable<DynamicItens[]>;
  myControl = new FormControl();
  size = 1;
  constructor(private http: HttpClient) {

  }
  isAdd(): boolean {
    return this.controller.type == TypeField.autocomplete_add;
  }
  eventAdd() {
    this.autoSelect.emit({ name: this.controller.formName, item: "", type: "Add" })
  }
  ngOnInit() {
    if (this.controller.urlGetItens)
      this.http.get<any[]>(this.controller.urlGetItens).subscribe(res => {
        this.controller.options = res
        var option = this.controller.options.find(p => p.key == this.form.controls[this.controller.formName].value);
        if (option)
          this.myControl.setValue(option.description)
      });
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    this.ControlMain();
  }
  ControlMain() {
    var formvalue = this.form.controls[this.controller.formName].value;
    var controllervalue = this.controller.value;
    setInterval(() => {
      var form = this.form.controls[this.controller.formName];
      if (form.value != this.controller.value) {
        if (this.form.value != formvalue) {
          formvalue = this.form.controls[this.controller.formName].value
          this.controller.value = form.value;
          controllervalue = formvalue;
          var option = (this.controller.options as DynamicItens[]).filter(option => option.key == formvalue)[0];
          if (option) {
            this.myControl.setValue(option.description)
            this.autoSelect.emit({ name: this.controller.formName, item: option.key })
          }
        }

        if (controllervalue != this.controller.value) {
          formvalue = this.controller.value;
          controllervalue = formvalue;
          form.setValue(controllervalue);
          var option = (this.controller.options as DynamicItens[]).filter(option => option.key == controllervalue)[0];
          if (option) {
            this.myControl.setValue(option.description)
            this.autoSelect.emit({ name: this.controller.formName, item: option.key })
          }
        }
      } 
      if (this.myControl.value == null) {
        if (this.controller.options.length > 0) {
          var option = (this.controller.options as DynamicItens[]).filter(option => option.key == formvalue)[0];
          if (option) {
            this.myControl.setValue(option.description)
          }
        }
      }
      if (typeof this.controller.options !== "string" && form.value == 0) {
        if (this.controller.options.length == 1) {
          var option = (this.controller.options as DynamicItens[])[0];
          this.myControl.setValue(option.description)
          form.setValue(option.key);
          this.autoSelect.emit({ name: this.controller.formName, item: option.key })
        }
      }
    }, 1000);
  };
  isrefresh = false;
  changeValue() {
    this.myControl.setValue('');
    this.form.controls[this.controller.formName].setValue(0);
  }
  private _filter(value: string): DynamicItens[] {
    if (!(typeof this.controller.options === "string")) {
      const filterValue = value.toLowerCase();

      var options = (this.controller.options as any[]).filter(option => (option.description).toLowerCase().includes(filterValue));
      this.size = options.length;
      return options;
    }
  }
  onChange(option) {
    if (option) {
      var _optionItem = (this.controller.options as DynamicItens[]).find(p => p.description == option);
      this.form.controls[this.controller.formName].setValue(_optionItem.key);
      this.autoSelect.emit({ name: this.controller.formName, item: _optionItem.key })
    } else {
      this.form.controls[this.controller.formName].setValue(0);
      this.myControl.setValue('');
    }
  }
}