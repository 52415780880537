import { Component, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControl } from '../dynamic-forms/dynamic-forms.model';

// text,email,tel,textarea,password,
@Component({
    selector: 'decimal-box',
    template: `<div
    [formGroup]="form">
        <input autocomplete="off"
            class="form-control"
            [id]="control.formName"
            [formControlName]="control.formName"
            [readonly]="!control.readonly ? '' : null"
            [attr.disabled]="!control.visible ? '' : null"
            type="number"
            placeholder="0,00">
</div>`,
styleUrls: ['./inputs.scss']
})
export class DecimalComponent {
    @Input() @Output() control: DynamicFormControl;
    @Input() @Output() form: FormGroup;
}